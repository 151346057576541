.contact-wrapper {
    background-size: cover;
    color: #00ffcc;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 200px);
  }
  
  .spacer {
    height: 50px;
  }
  
  .contact-form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .contact-form {
    background: #1a1a1a;
    padding: 2rem;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  
  .contact-form input,
  .contact-form textarea {
    background: #333;
    border: none;
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 5px;
    color: #00ffcc;
  }
  
  .contact-form button {
    background: #00ffcc;
    border: none;
    padding: 1rem;
    border-radius: 5px;
    color: #000;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .contact-form button:hover {
    background: #00cc99;
  }
  