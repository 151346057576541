@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.logo-container {
  display: flex;
  align-items: center;
  font-family: 'Changa', sans-serif;
  font-size: 30px;
}

.logo-text {
  color: #FFFEFE;
  font-weight: 600;
}

.cursor {
  display: inline-block;
  background-color: #808080;
  width: 2px;
  height: 1em;
  margin-left: 2px;
  animation: blink 1s step-end infinite;
}
