.footer-wrapper {
  background: rgba(0, 0, 0, 0.8);
  color: #00FFAA;
  padding: 2rem 0;
  text-align: center;
  width: 100%;
  position: relative;
  bottom: 0;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.slogan-text {
  font-size: 1rem;
  margin-bottom: 1rem;
  color: #fff;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.social-links a {
  color: inherit;
  font-size: 1.5rem;
  transition: color 0.3s;
}

.social-links a:hover {
  color: #FFF;
}

.footer-text {
  font-size: 0.875rem;
  margin-bottom: 1rem;
  color: #aaa;
}
