.header-wrapper {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: var(--header-bg-color, rgba(255, 255, 255, 0.0));
  transition: background-color 0.3s ease-in-out;
  z-index: 1000;
}

.header-container {
  width: 90%;
  max-width: 1200px;
  padding: 0.3rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-links a {
  margin: 0 1rem;
  text-decoration: none;
  color: var(--link-color, white);
  transition: color 0.3s ease-in-out;
  position: relative;
  font-size: 1rem;
}

.nav-links a:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -4px;
  width: 100%;
  height: 2px;
  background-color: white;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease-in-out;
}

.nav-links a:hover:after {
  transform: scaleX(1);
  transform-origin: left;
}
