#hero {
    height: 55vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    position: relative;
    background: #f9f9f9;
  }
  
  .slide-content {
    position: absolute;
    bottom: 20%;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 10px;
    max-width: 80%;
    color: white;
  }
  
  .title {
    font-size: 2rem;
    margin: 0;
    padding: 0;
  }
  
  .subtitle {
    font-size: 1rem;
    margin: 0.5rem 0 1rem 0;
  }
  
  .button-container {
    display: flex;
    gap: 1rem;
  }
  
  .button {
    padding: 0.5rem 1rem;
    background-color: transparent;
    color: orange;
    border: 2px solid orange;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .button.primary {
    background-color: orange;
    color: white;
  }
  
  .button:hover {
    background-color: orange;
    color: white;
  }
  
  .button.primary:hover {
    background-color: darkorange;
    color: white;
  }
  
  .carousel .slide img {
    height: 55vh;
    object-fit: cover;
  }
  